import React, {useEffect, useState} from 'react';
import {Button, Flex, Form, Input, message, Modal, Popconfirm, Space, Tooltip, Avatar, Select, Badge} from 'antd';
import {DeleteOutlined, EditOutlined, ExclamationCircleFilled, EyeOutlined,} from '@ant-design/icons';
import DataTable from '../../common/DataTable';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {
  createSuggest,
  deleteManySuggest,
  deleteSuggese,
  getSuggestPaging, getWebsite,
  updateSuggest,
} from '../../../services/api';
import ModalFunction from './ModalFunction';
import {handleExport} from '../../common/ExportExcel';
import {APPROVE_STATUS, TYPE_SUGGEST, TYPE_SUGGESTS} from "../../common/Enum";

const headerRowFile = [
  'Mã đơn hàng',
  'Người thêm',
  'Link đề xuất',
  'Domain',
  'Ngày tạo',
];

const {confirm} = Modal;

export default function ListSuggest() {
  document.title = 'Danh sách đề xuất | CHECKLINK SERVICE OKVIP';
  const [form] = Form.useForm();
  const {Option} = Select;
  const navigate = useNavigate();
  const [isModalFunction, setIsModalFunction] = useState(false);
  const [editData, setEditData] = useState();
  const [titleModal, setTitleModal] = useState('Thêm Đề Xuất');
  const [listSuggest, setListSuggest] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchDomainOrUserName, setSearchDomainOrUserName] = useState('');
  const [searchDomain, setSearchDomain] = useState('');
  const [searchType, setSearchType] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [website, setWebsite] = useState([]);
  const [pageWebsite, setPageWebsite] = useState(1);
  const [maxPageWebsite, setMaxPageWebsite] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [searchFilterDomain, setSearchFilterDomain] = useState('');
  const [isLoadingSuggest, setIsLoadingSuggest] = useState(false);
  const [prefix, setPrefix] = useState(TYPE_SUGGEST.OTHER);

  useEffect(() => {
    let searchDebounceTimeout;
    if (search || searchDomainOrUserName) {
      searchDebounceTimeout = setTimeout(async () => {
        fetchDataSuggest();
      }, 300);
    } else {
      fetchDataSuggest();
    }
    return () => clearTimeout(searchDebounceTimeout);
  }, [params, search, searchDomain, searchDomainOrUserName, searchType]);

  useEffect(() => {
    fetchDataWebsite(pageWebsite)
  }, [])

  useEffect(() => {
    fetchDataWebsite(pageWebsite)
  }, [searchFilterDomain])

  const fetchDataSuggest = () => {
    setLoading(true);

    getSuggestPaging({
      limit: params.limit,
      page: params.page,
      search,
      domain: searchDomain,
      searchDomainOrUserName,
      type: searchType,
    }).then((res) => {
      setTotalPage(res?.data?.pagination?.total);
      setListSuggest(res?.data?.items);
    }).catch(async (error) => {
      message.error('Lỗi khi lấy danh sách suggest:');
    })
      .finally(() => setLoading(false));
  };

  const dataSource = listSuggest?.map((item) => {
    const domain = item?.websiteId?.domain;
    const domainReplace = domain.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
    return {
      key: item?._id,
      name: item?.name,
      createName: item?.userId?.name,
      linkSuggest: item?.linkSuggest,
      domain,
      domainReplace,
      created_at: moment(item?.created_at)?.format('DD/MM/YYYY HH:mm'),
      updated_at: moment(item?.updated_at)?.format('DD/MM/YYYY HH:mm'),
      guaranteed: item?.guaranteed ? moment(item?.guaranteed)?.format('DD/MM/YYYY HH:mm') : '--',
      timer: item?.timer === 0 ? 'Không giới hạn' : item?.timer + 's',
      type: item?.type,
    }
  })

  //xử lý khi chuyển trang
  const onPageChange = (page, limit) => {
    setParams({...params, limit, page});
  };

  const onEdit = (id) => {
    const dataEdit = listSuggest.filter((item) => item._id === id);
    setEditData(dataEdit[0]);
    setIsModalFunction(true);
    setTitleModal('Cập nhật Đề Xuất');
  };

  const onDelete = async (id) => {
    try {
      const resWebsite = await deleteSuggese(id);
      if (resWebsite?.statusCode === 200) {
        message.success('Xóa đề xuất thành công!');
        fetchDataSuggest();
      } else {
        message.error('Xóa đề Xuất thất bại!');
      }
    } catch (error) {
      console.log(error);
      message.error('Đã xảy ra lỗi, vui lòng thử lại!');
    }
  };

  //Hàm này để xem thông tin chi tiết (đi tới detail)
  const showInfo = (id) => {
    navigate(`/detail/${id}`);
  };

  const onSearch = (value, input) => {
    if (input === 'search') setSearch(value);
    if (input === 'searchDomainOrUserName') setSearchDomainOrUserName(value);
    setParams({...params, page: 1});
  };

  const handleRefresh = async () => {
    setSearch('');
    setSearchDomainOrUserName('');
    setParams({...params, page: 1, limit: 10});
  };

  const handleAdd = () => {
    setIsModalFunction(true);
    setEditData(null);
    form.resetFields();
    setTitleModal('Thêm Đề Xuất');
  };

  const onFinish = async (data) => {
    try {
      setIsLoadingSuggest(true)
      data.guaranteed = data.guaranteed.format('YYYY-MM-DD HH:mm:ss');
      data.linkSuggest = data.linkSuggest.startsWith('http')
        ? data.linkSuggest
        : 'https://' + data.linkSuggest;

      if (data?.telegramId) {
        if (data.telegramId.startsWith('https://t.me/')) {
          data['telegramId'] = data.telegramId;
        } else {
          data.telegramId = 'https://t.me/' + data.telegramId;
        }
      }
      let res;
      if (editData) {
        data.id = editData?._id;
        res = await updateSuggest({...data, timer: Number(data.timer), type: prefix});
      } else {
        res = await createSuggest({...data, timer: Number(data.timer), type: prefix});
      }
      if (res.statusCode === 200) {
        message.success(
          editData ? 'Cập nhật đề xuất thành công!' : 'Thêm đề xuất thành công!'
        );
        setIsModalFunction(false);
        setIsLoadingSuggest(false)
        form.resetFields();
        fetchDataSuggest();
      } else {
        throw new Error(
          editData ? 'Cập nhật đề xuất thất bại!' : 'Thêm đề xuất thất bại!'
        );
      }
    } catch (error) {
      console.log(error, 'err');
      message.error('Đã xảy ra lỗi, vui lòng thử lại!');
    }
  };

  const handleDeleteSelectRows = async () => {
    confirm({
      title: 'Xác nhận xóa?',
      content: `Bạn muốn xóa ${selectedRowKeys.length} đề xuất đã chọn?`,
      icon: <ExclamationCircleFilled/>,
      okText: 'Xóa ',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        handleDeleteMulti();
      },
      onCancel() {
      },
      className: 'custom-btn-class',
    });
  };

  const handleDeleteMulti = async () => {
    const resDelete = await deleteManySuggest({ids: selectedRowKeys});

    if (resDelete?.statusCode !== 200) {
      return message.error('Xóa suggest thất bại!');
    }

    message.success('Xóa suggest thành công!');
    setSelectedRowKeys([]);
    await fetchDataSuggest();
  };

  const fetchDataWebsite = (page, isLoadMore = false) => {
    getWebsite({limit: 50, page, search: searchFilterDomain}).then(res => {
      if (res?.data?.items?.length > 0) {
        // calculate max page
        const total = +res?.data?.pagination?.total || 0;
        const limit = +res?.data?.pagination?.limit || 0;
        const maxPage = Math.ceil(total / limit);
        setMaxPageWebsite(maxPage);

        const formatData = res?.data?.items.map((item) => item?.website || item);
        if (isLoadMore) {
          setWebsite((prev) => {
            const newItems = formatData.filter((item) => !prev.some((prevItem) => prevItem._id === item._id));
            return [...prev, ...newItems];
          });
          setIsLoadMore(false)
        } else {
          const newItems = [...website, ...formatData]
          const uniqueItems = newItems.filter((item, index) => newItems.findIndex((t) => t._id === item._id) === index)
          setWebsite(uniqueItems);
        }
      }
    })
  };

  const onScroll = async (event) => {
    const target = event.target
    if (!isLoadMore && target.scrollTop + target.offsetHeight === target.scrollHeight) {

      if (pageWebsite >= maxPageWebsite) return

      setIsLoadMore(true)
      target.scrollTo(0, target.scrollHeight)
      const newPage = pageWebsite + 1
      setPageWebsite(newPage)
      await fetchDataWebsite(newPage, true)
    }
  }

  const onSearchFilterDomain = async (value) => {
    setSearchFilterDomain(value);
    setPageWebsite(1);

    await fetchDataWebsite(1);
  }

  const handleChangeFilterDomain = (value) => {
    setSearchDomain(value);
    setParams({...params, page: 1});
  }
  const onChangeType = (value) => {
    setSearchType(value);
    setParams({...params, page: 1});
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (_, text, index) => (params.page - 1) * params.limit + index + 1,
      width: 60,
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'name',
    },
    {
      title: 'Loại đơn hàng',
      dataIndex: 'type',
      width: 130,
      render: (text) => {
        return (
          <Badge
            count={text === TYPE_SUGGEST.STANDARD ? 'Chuẩn' : text === TYPE_SUGGEST.INDEX ? 'Index' : text === TYPE_SUGGEST.CLN ? 'Check Link' : 'Khác'}
            showZero
            color={text === TYPE_SUGGEST.STANDARD ? 'green' : text === TYPE_SUGGEST.INDEX ? 'orange' : text === TYPE_SUGGEST.CLN ? 'purple' : 'cyan'}
          />
        );
      },
    },
    {
      width: 200,
      title: 'Người tạo',
      dataIndex: 'createName',
      render: (_, {createName}) => {
        return createName ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#e5e5e5', color: '#000'}}>{createName.charAt(0)}</Avatar>
            <span className="ml-1">{createName}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      render: (domain, _) => {
        return (
          <a className='text-[#4096ff] ' href={domain} target='_blank'>
            {_.domainReplace}
          </a>
        );
      },
    },
    {
      title: 'Link đề xuất',
      dataIndex: 'linkSuggest',
      render: (_, {linkSuggest}) => {
        return (
          <a className='long-text' href={linkSuggest} target='_blank'>
            {linkSuggest}
          </a>
        );
      },
    },
    {
      title: 'Thời gian load link',
      dataIndex: 'timer',
      width: 150,
    },
    {
      title: 'Bảo hành',
      dataIndex: 'guaranteed',
      width: 150,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      width: 150,
    },
    {
      fixed: 'right',
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <Flex className='gap-2 items-center'>
          <Tooltip title='chi tiết'>
            <button
              onClick={() => showInfo(record.key)}
              className='bg-blue-600 action-button'
            >
              <EyeOutlined/>
            </button>
          </Tooltip>
          <button
            onClick={() => onEdit(record.key)}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined/>
          </button>

          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Xóa Đề Xuất'
            description='Bạn muốn xóa đề xuất này?'
            onConfirm={() => onDelete(record.key)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined/>
            </button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <div className='flex gap-2 mt-5'>
        <Space.Compact style={{width: 300, marginTop: '-20px', height: 'fit-content'}}>
          <Input
            placeholder='Nhập tên đề xuất hoặc telegram'
            value={search}
            onChange={(e) => onSearch(e.target.value, 'search')}
            allowClear
          />
        </Space.Compact>

        <Select
          placeholder='Lọc theo domain'
          className='w-[240px] mb-6 add-user-btn'
          showSearch
          filterOption={(input, option) =>
            option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >=
            0
          }
          onPopupScroll={onScroll}
          loading={isLoadMore}
          onSearch={onSearchFilterDomain}
          onChange={handleChangeFilterDomain}
        >
          {website?.length > 0 &&
            website?.map((item) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item.domain}
                </Option>
              );
            })}
        </Select>

        <Select
          className='w-[240px] mb-6 add-user-btn'
          onChange={onChangeType}
          placeholder='Lọc theo loại đơn hàng'
          defaultValue=''
          showSearch
          filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value=''>Tất cả loại đơn hàng</Option>
          {TYPE_SUGGESTS?.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>

        {/* <Space.Compact style={{width: 300, marginTop: '-20px', height: 'fit-content'}}>
          <Input
            placeholder='Nhập tên domain, username'
            value={searchDomainOrUserName}
            onChange={(e) => onSearch(e.target.value, 'searchDomainOrUserName')}
            allowClear
          />
        </Space.Compact> */}
      </div>
      <div className='flex gap-3 mt-5 pt-4'>
        <Button
          type='primary'
          className='add-btn flex items-center'
          onClick={handleAdd}
        >
          Thêm Đề Xuất
        </Button>
        <Button
          onClick={() => handleExport(listSuggest, headerRowFile, 'de-xuat')}
          className='btn-excel flex items-center'
          disabled={listSuggest?.length === 0}
        >
          Xuất Excel
        </Button>
        <Button
          type='primary'
          danger
          onClick={handleDeleteSelectRows}
          disabled={!selectedRowKeys.length}
          className='btn-del-all flex items-center '
        >
          Xóa tất cả
        </Button>
        <Button
          onClick={handleRefresh}
          type='primary'
          className='add-reset flex items-center bg-slate-600 text-white'
          disabled={loading}
        >
          Reload
        </Button>
      </div>
      <DataTable
        listData={dataSource}
        pageSize={params.limit}
        columns={columns}
        indexPage={params.page}
        totalPage={+totalPage}
        onPageChange={onPageChange}
        loading={loading}
        scroll={{x: 1500}}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <ModalFunction
        isLoadingSuggest={isLoadingSuggest}
        isModalFunction={isModalFunction}
        setIsModalFunction={setIsModalFunction}
        dataEdit={editData}
        onFinish={onFinish}
        setPrefix={setPrefix}
        title={titleModal}
        form={form}
      />
    </>
  );
}
