export default function Privacy() {
    return (
        <div className="p-4 inside-article">

            <header className="entry-header">
                <h1 className="entry-title text-4xl" itemProp="headline">Chính sách bảo mật</h1>
            </header>


            <div className="entry-content mt-10 flex flex-col gap-4" itemProp="text">

                <h2 className="wp-block-heading text-3xl">Chính sách bảo mật</h2>


                <p>Chính sách bảo mật này mô tả các chính sách và quy trình của chúng tôi về việc thu thập, sử dụng và
                    tiết lộ thông tin của bạn khi bạn sử dụng Dịch vụ và cho bạn biết về quyền riêng tư của bạn và cách
                    pháp luật bảo vệ bạn.</p>


                <p>Chúng tôi sử dụng dữ liệu Cá nhân của bạn để cung cấp và cải thiện Dịch vụ. Bằng cách sử dụng Dịch
                    vụ, Bạn đồng ý với việc thu thập và sử dụng thông tin theo Chính sách bảo mật này.</p>


                <h2 className="wp-block-heading text-3xl">Giải thích và định nghĩa</h2>


                <p className=""><strong>Giải thích</strong></p>


                <p>Các từ mà chữ cái đầu được viết hoa có nghĩa được xác định theo các điều kiện sau.</p>


                <p>Các định nghĩa sau sẽ có cùng ý nghĩa bất kể chúng xuất hiện ở số ít hay số nhiều.</p>


                <p className=""><strong>Định nghĩa</strong></p>


                <p>Vì mục đích của Chính sách bảo mật này:</p>


                <p>Bạn có nghĩa là cá nhân truy cập hoặc sử dụng Dịch vụ hoặc công ty hoặc pháp nhân khác thay mặt cho
                    cá nhân đó đang truy cập hoặc sử dụng Dịch vụ, nếu có thể.</p>


                <p>Công ty (được gọi là “Công ty”, “Chúng tôi”, “Chúng tôi” hoặc “Của chúng tôi” trong Thỏa thuận này)
                    đề cập đến thư của bạn.</p>


                <p>Chi nhánh có nghĩa là một thực thể kiểm soát, được kiểm soát bởi hoặc dưới sự kiểm soát chung với một
                    bên, trong đó “kiểm soát” có nghĩa là quyền sở hữu từ 50% trở lên cổ phần, lãi cổ phần hoặc chứng
                    khoán khác có quyền bỏ phiếu bầu cử giám đốc hoặc cơ quan quản lý khác.</p>


                <p>Tài khoản có nghĩa là một tài khoản duy nhất được tạo để Bạn truy cập Dịch vụ của chúng tôi hoặc các
                    bộ phận của Dịch vụ của chúng tôi.</p>


                <p>Trang web đề cập đến So sánh giá khóa học, có thể truy cập từ https://checklinksokvip.com</p>


                <p>Dịch vụ đề cập đến trang web.</p>


                <p>Quốc gia đề cập đến: Việt Nam</p>


                <p>Nhà cung cấp dịch vụ có nghĩa là bất kỳ cá nhân hoặc pháp nhân nào xử lý dữ liệu thay mặt cho Công
                    ty. Nó đề cập đến các công ty hoặc cá nhân bên thứ ba được Công ty thuê để tạo điều kiện cho Dịch
                    vụ, cung cấp Dịch vụ thay mặt cho Công ty, để thực hiện các dịch vụ liên quan đến Dịch vụ hoặc hỗ
                    trợ Công ty phân tích cách sử dụng Dịch vụ.</p>


                <p>Dịch vụ truyền thông xã hội của bên thứ ba đề cập đến bất kỳ trang web hoặc bất kỳ trang web mạng xã
                    hội nào mà Người dùng có thể đăng nhập hoặc tạo tài khoản để sử dụng Dịch vụ.<br/>Dữ liệu cá nhân là
                    bất kỳ thông tin nào liên quan đến một cá nhân được xác định hoặc nhận dạng.</p>


                <p>Cookies là các tệp nhỏ được đặt trên máy tính, thiết bị di động hoặc bất kỳ thiết bị nào khác của một
                    trang web, có chứa các chi tiết về lịch sử duyệt web của bạn trên trang web đó trong số nhiều lần sử
                    dụng.</p>


                <p>Dữ liệu sử dụng đề cập đến dữ liệu được thu thập tự động, được tạo bởi việc sử dụng Dịch vụ hoặc từ
                    chính cơ sở hạ tầng Dịch vụ (ví dụ: thời lượng truy cập trang).</p>


                <h2 className="wp-block-heading text-3xl ">Thu thập và sử dụng dữ liệu cá nhân của bạn</h2>


                <p>Các loại dữ liệu được thu thập</p>


                <p className=""><strong>Dữ liệu cá nhân</strong></p>


                <p>Trong khi sử dụng Dịch vụ của chúng tôi, chúng tôi có thể yêu cầu Bạn cung cấp cho chúng tôi thông
                    tin nhận dạng cá nhân nhất định có thể được sử dụng để liên hệ hoặc nhận dạng Bạn. Thông tin nhận
                    dạng cá nhân có thể bao gồm, nhưng không giới hạn ở:</p>


                <p>Địa chỉ email<br/>Tên và họ <br/>Dữ liệu sử dụng</p>


                <p className=""><strong>Dữ liệu sử dụng được thu thập tự động khi sử dụng Dịch vụ</strong></p>


                <p>Dữ liệu sử dụng có thể bao gồm thông tin như địa chỉ Giao thức Internet của thiết bị của bạn (ví dụ:
                    địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang Dịch vụ của chúng tôi mà bạn truy
                    cập, thời gian và ngày truy cập của bạn, thời gian dành cho các trang đó , số nhận dạng thiết bị duy
                    nhất và dữ liệu chẩn đoán khác.</p>


                <p>Khi bạn truy cập Dịch vụ bằng hoặc thông qua thiết bị di động, Chúng tôi có thể tự động thu thập một
                    số thông tin nhất định, bao gồm nhưng không giới hạn ở loại thiết bị di động bạn sử dụng, ID duy
                    nhất của thiết bị di động, địa chỉ IP của thiết bị di động của bạn , Hệ điều hành di động của bạn,
                    loại trình duyệt Internet di động Bạn sử dụng, số nhận dạng thiết bị duy nhất và dữ liệu chẩn đoán
                    khác.</p>


                <p>Chúng tôi cũng có thể thu thập thông tin mà trình duyệt của bạn gửi bất cứ khi nào bạn truy cập Dịch
                    vụ của chúng tôi hoặc khi bạn truy cập Dịch vụ bằng hoặc thông qua thiết bị di động.</p>


                <h2 className="wp-block-heading text-3xl ">Công nghệ theo dõi và cookie</h2>


                <p>Chúng tôi sử dụng Cookies và các công nghệ theo dõi tương tự để theo dõi hoạt động trên Dịch vụ của
                    chúng tôi và lưu trữ một số thông tin nhất định. Các công nghệ theo dõi được sử dụng là đèn hiệu,
                    thẻ và tập lệnh để thu thập và theo dõi thông tin cũng như cải thiện và phân tích Dịch vụ của chúng
                    tôi.</p>


                <p>Bạn có thể hướng dẫn Trình duyệt của mình từ chối tất cả Cookies hoặc cho biết khi nào.</p>
            </div>

        </div>
    )
}