import React, {useEffect, useState} from 'react';
import {Avatar, Button, Form, Input, message, Popconfirm, Space,} from 'antd';
import {DeleteOutlined, EditOutlined, SearchOutlined,} from '@ant-design/icons';
import {deleteConfigIndex, getConfigIndex,} from '../../../services/api';
import DataTable from '../../common/DataTable';
import moment from 'moment';
import {useNavigate} from "react-router-dom";
import {CONFIG_INDEX} from "../../common/Enum";
import ModalConfigIndex from "./ModalConfigIndex";

export default function ConfigIndex() {
  document.title = 'Danh sách cấu hình index | CHECKLINK SERVICE OKVIP';
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [config, setConfig] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 1,
  });
  const [loading, setLoading] = useState(true);
  const [dataEdit, setDataEdit] = useState();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.limit]);

  useEffect(() => {
    fetchData();
  }, [search]);

  const columns = [
    {
      title: 'Tên cấu hình',
      dataIndex: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Thông tin cấu hình',
      width: 350,
      render: (text, row) => {
        if ([CONFIG_INDEX.SYNBYTE, CONFIG_INDEX.ONEINDEXER].includes(row.code)) {
          return row?.apiKey || '--'
        }
        if ([CONFIG_INDEX.ONEHPING, CONFIG_INDEX.INDEXINJECT].includes(row.code)) {
          return (row?.username) ?
            <>
              <p>{row?.username}</p>
              <p> ***********</p>
            </>
            : ''
        }
        if ([CONFIG_INDEX.GOOGLE].includes(row.code)) {
          return row?.serviceAccount?.length ? `${row?.serviceAccount?.length} Service` : ''
        }
        return ''
      }
    },
    {
      title: 'Team',
      dataIndex: 'teamName',
      render: (text) => {
        return text ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#fde3cf', color: '#f56a00'}}>{text.charAt(0)}</Avatar>
            <span className="text-[#f56a00] ml-1">{text}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'updated_at',
    },
    {
      title: 'Thao tác',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (text, data, index) => (
        <Space size='middle' className='gap-2 items-center '>
          <button
            onClick={() => handleEdit(data)}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined/>
          </button>
          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{className: 'bg-blue-600 text-white',}}
            title='Xóa cấu hình index'
            description='Bạn muốn xóa cấu hình này?'
            onConfirm={() => handleDelete(data)}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <button className='bg-red-600 action-button'>
              <DeleteOutlined/>
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const dataSource = config?.map((item) => {
    return {
      key: item._id,
      name: item.name,
      code: item.code,
      username: item.username,
      password: item.password,
      apiKey: item.apiKey,
      teamId: item?.teamId?._id,
      teamName: item?.teamId?.name || '',
      created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
      updated_at: moment(item.updated_at).format('DD/MM/YYYY HH:mm'),
      serviceAccount: item.serviceAccount,
    };
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getConfigIndex({
        page: pagination.page,
        limit: pagination.limit,
        search,
      });
      setConfig(res.data.items);
      setPagination((prev) => ({
        ...prev,
        total: res.data.pagination.total,
      }))
    } catch (e) {
      message.error('Lỗi hệ thống');
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (data) => {
    const res = await deleteConfigIndex(data.key);
    if (res?.status) {
      message.success('Xóa thành công!');
      await fetchData();
    }
  };

  const onPageChange = (page, limit) => {
    setPagination((prev) => ({
      ...prev,
      page: page,
      limit: limit,
    }));
  };

  const onSearch = async (e) => {
    setSearch(e.target.value)
  };

  const reload = async () => {
    setSearch('')
  };

  const handleSearch = async () => {
    setPagination({
      page: 1,
      limit: 15,
      total: 1,
    });
    await fetchData();
  }

  const handleEdit = (value) => {
    setDataEdit(value);
    setShow(true);
    form.setFieldsValue({
      name: value.name,
      code: value.code,
      username: value.username,
      password: value.password,
      apiKey: value.apiKey,
      teamId: value.teamId,
      key: value.key,
    });
  }

  const handleAdd = () => {
    setShow(true);
    form.resetFields();
  }

  const handleCloseModal = () => {
    setShow(false);
    form.resetFields();
  };

  const submitModal = async () => {
    await fetchData();
    handleCloseModal();
  }

  return (
    <div>
      <div className='mt-5 flex justify-between'>
        <div className='flex gap-2 '>
          <Space.Compact style={{width: 300, marginTop: '-20px'}}>
            <Input
              addonBefore={<SearchOutlined/>}
              placeholder='Tìm kiếm cấu hình'
              allowClear
              onChange={onSearch}
              value={search}
            />
          </Space.Compact>
          <Button
            type='primary'
            className='add-btn flex items-center'
            onClick={() => handleAdd()}
          >
            Thêm mới
          </Button>
          <Button
            type='primary'
            onClick={() => reload()}
            className='add-reset flex items-center bg-slate-600 text-white'
            disabled={loading}
          >
            Reload
          </Button>
        </div>
      </div>
      <DataTable
        columns={columns}
        listData={dataSource}
        pageSize={+pagination.limit}
        indexPage={+pagination.page}
        totalPage={+pagination.total}
        onPageChange={onPageChange}
        scroll={{x: 1500}}
      />

      <ModalConfigIndex
        form={form}
        handleCloseModal={handleCloseModal}
        title={!dataEdit ? 'Cấu hình index' : 'Sửa cấu hình index'}
        show={show}
        submitModal={submitModal}
        dataEdit={dataEdit}
      />
    </div>
  );
}
