import React, { useState } from 'react';
import { Upload, Button, message, Space } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const style = {
    color: '#4096ff',
    borderColor: '#4096ff',
    transition: 'color 0.3s',
};

const ImportExcelCheckLinkFree = (props) => {
    const { setLinks } = props;
    const [excelData, setExcelData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hovered, setHovered] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (excelData !== null) {
            setExcelData(null);
        }
    }, [excelData]);

    const handleFileChange = async (info) => {
        setLoading(true);

        if (info.file.status === 'done') {
            try {
                const file = info.file.originFileObj;
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(file);
                const worksheet = workbook.worksheets[0];
                const rows = [];
                worksheet.eachRow((row, rowNumber) => {
                    if (rowNumber > 1) { // Skip the header row
                        const firstCell = row.getCell(1).text;
                        rows.push({ url: firstCell, indexed: null });
                    }
                });

                // Save the extracted data to a state variable
                setLinks(rows);
                setExcelData(rows);
                message.success('Upload excel thành công');
            } catch (error) {
                console.log(error);
                message.error(`${info.file.name} file upload thất bại.`);
            }
        } else if (info.file.status === 'error') {
            message.error('Error uploading file.');
        }
        setLoading(false);
    };

    const customRequest = ({ file, onSuccess, onError }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const exportToExcel = async () => {
        // Tạo một workbook mới
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        // Thêm dữ liệu mẫu
        worksheet.columns = [
            { header: 'URL', key: 'col1', width: 50 },
        ];

        worksheet.addRow(['https://link.net']);
        worksheet.addRow(['https://link.com']);
        // Tạo tập tin Excel
        const buffer = await workbook.xlsx.writeBuffer();

        // Tạo blob từ buffer
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Tạo URL cho blob
        const url = window.URL.createObjectURL(blob);

        // Tạo một link để tải xuống
        const a = document.createElement('a');
        a.href = url;
        a.download = 'file-mau.xlsx';
        document.body.appendChild(a);
        a.click();

        // Xóa link sau khi đã tải xuống
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    return (
        <Space>
            <Upload
                customRequest={customRequest}
                onChange={handleFileChange}
                showUploadList={false}
            >
                <Button
                    loading={loading}
                    disabled={loading === true}
                    icon={<UploadOutlined />}
                    style={hovered === true ? style : null}
                    onMouseOver={() => setHovered(true)}
                    onMouseOut={() => setHovered(false)}
                >
                    Upload Excel
                </Button>
            </Upload>
            <Button
                icon={<DownloadOutlined />}
                onClick={exportToExcel}
                className='bg-[#16a34a] text-white border-[#16a34a] '
            >
                Xuất File Mẫu
            </Button>
        </Space>
    );
};

export default ImportExcelCheckLinkFree;
