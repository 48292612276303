import React, {useState, useEffect} from 'react';
import {
  Space,
  Tag,
  message,
  Popconfirm,
  Button,
  Modal,
  Input,
  Form,
  Tooltip, Avatar, Select, Flex,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from '@ant-design/icons';
import {
  getWebsite,
  DeleteWebsiteById,
  getWebsiteById,
  createWebsite,
  updateWebSite,
  DeleteWebsite, getUsers, getTeams,
} from '../../../services/api';
import DataTable from '../../common/DataTable';
import moment from 'moment';
import ModalView from './ModalView';
import ModalFunction from './ModalFunction';
import {useNavigate} from 'react-router-dom';
import {debounce, formatVND} from '../../common/function';
import {handleExport} from '../../common/ExportExcel';
import {DOMAIN_STATUS} from "../../common/Enum";

const {confirm} = Modal;

const headerRowFile = ['Domain', 'Người tạo', 'Team', 'Ngày tạo'];

export default function ListSite() {
  document.title = 'Danh sách domain | CHECKLINK SERVICE OKVIP';

  const navigate = useNavigate();
  const [websiteData, setWebsiteData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFunction, setIsModalFunction] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [dataView, setDataView] = useState();
  const [editData, setEditData] = useState();
  //pagination
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    search: '',
    teamId: '',
    createdBy: '',
    status: '',
  });
  const [totalPage, setTotalPage] = useState('');
  const [loading, setLoading] = useState(true);
  const [titleModal, setTitleModal] = useState('Thêm Domain');
  const [users, setUser] = useState([]);
  const [roleAuth, setRoleAuth] = useState('');
  const [teams, setTeams] = useState([]);

  const fetchTeam = async () => {
    try {
      let resData = await getTeams({ limit: 9999 });
      setTeams(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  const [isLoadingWebsite, setIsLoadingWebsite] = useState(false);

  const [form] = Form.useForm();
  const {Option} = Select;

  useEffect(() => {
    getAllWebsite();
  }, [pagination]);

  useEffect(() => {
    const role = localStorage.getItem('role');
    setRoleAuth(role);

    if (['admin', 'leader'].includes(role)) {
      fetchTeam();
      getAllUser()
    }
  }, []);

  const getAllWebsite = async () => {
    setLoading(true);
    try {
      const res = await getWebsite(pagination);
      setWebsiteData(res?.data?.items);
      setTotalPage(res?.data?.pagination?.total);
    } catch (error) {
      message.error('Lỗi khi lấy danh sách domain!', error);
      console.log(error, 'err');
    } finally {
      setLoading(false);
    }
  };

  const getAllUser = async () => {
    const res = await getUsers({role: 'user', limit: 99999});
    const mapData = res?.data?.items?.map(item => {
      return {
        _id: item._id,
        teamId: item.teamId?._id ?? '',
        name: item.name,
        email: item.email,
      }
    })
    setUser(mapData);
  }

  const dataSource = websiteData?.map((item) => {
    const domain = item?.website?.domain ?? item?.domain;
    const domainReplace = domain.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
    return {
      key: item?.website?._id ?? item?._id,
      createName: item?.website?.createdBy?.name ?? item?.createdBy?.name,
      description: item?.website?.description ?? item?.description,
      domain: item?.website?.domain ?? item?.domain,
      domainReplace: domainReplace,
      team: item?.website?.teamId?.name ?? item?.teamId?.name,
      created_at: moment(item?.created_at)?.format('DD/MM/YYYY HH:mm'),
      updated_at: moment(item?.updated_at)?.format('DD/MM/YYYY HH:mm'),
      status: item?.website?.status ?? item?.status,
      totalMoney: formatVND(item?.totalMoney ?? 0),
    };
  });

  const onPageChange = (page, limit) => {
    setPagination({...pagination, page, limit});
  };

  const onSearch = (e) => {
    setPagination({...pagination, page: 1, search: e.target.value});
  };

  const debounceSearch = debounce(onSearch, 600);

  //Cập nhật show folder
  const showFolder = (id) => {
    navigate(`/suggest/${id}`);
  };

  const showInfo = async (id) => {
    const dataWebsite = await getWebsiteById(id);
    setDataView(dataWebsite.data);

    setIsModalOpen(true);
  };

  const onEdit = async (site) => {
    setEditData(site);
    setTitleModal('Cập nhật Domain');
    setIsModalFunction(true);
  };

  //check xóa nhiều phần tử
  const handleDeleteSelectRows = async () => {
    confirm({
      title: 'Xác nhận xóa?',
      content: `Bạn muốn xóa ${selectedRowKeys.length} domain đã chọn?`,
      icon: <ExclamationCircleFilled/>,
      okText: 'Xóa ',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        handleDeleteMulti();
      },
      onCancel() {
      },
      className: 'custom-btn-class',
    });
  };

  // xử lý call api delete nhiều phần tử
  const handleDeleteMulti = async () => {
    const resDelete = await DeleteWebsite({data: selectedRowKeys});

    if (resDelete?.statusCode === 200) {
      message.success('Xóa domain thành công!');
      setSelectedRowKeys([]);
      getAllWebsite();
    } else {
      message.error('Xóa domain thất bại!');
    }
  };

  // del lẻ từng phần tử
  const onDelete = async (id) => {
    try {
      const resWebsite = await DeleteWebsiteById(id);

      if (resWebsite?.statusCode === 200) {
        message.success('Xóa domain thành công!');
        getAllWebsite();
      } else {
        message.error('Xóa domain thất bại!');
      }
    } catch (error) {
      message.error('Xóa domain thất bại!');
    }
  };

  const handleAdd = () => {
    setIsModalFunction(true);
    setEditData();
    form.resetFields();
    setTitleModal('Thêm Domain');
  };

  // xử lý cập nhật website
  const onFinish = async (data) => {
    try {
      setIsLoadingWebsite(true)
      data.domain = data.domain.startsWith('http')
        ? data.domain
        : 'https://' + data.domain;

      if (editData) {
        data.id = editData?.website?._id ?? editData?.key;
        const resUpdate = await updateWebSite(data, {
          domain: data.domain,
          description: data.description,
          userIdAddLinks: [data.userIdAddLinks],
          teamId: data.teamId,
          status: data.status
        });
        if (resUpdate?.statusCode === 200) {
          message.success('Cập nhật domain thành công!');
          setIsModalFunction(false);
          getAllWebsite();
          form.resetFields();
        } else {
          message.error('Cập nhật domain thất bại!');
        }
      } else {
        const resCreate = await createWebsite(data);
        if (resCreate?.statusCode === 200) {
          message.success('Thêm domain thành công!');
          setIsModalFunction(false);
          getAllWebsite();
          form.resetFields();
        } else {
          message.error('Thêm domain thất bại!');
        }
      }
    } catch (error) {
      console.error('Error in onFinish:', error);
      message.error('Đã xảy ra lỗi, vui lòng thử lại sau!');
    } finally {
        setIsLoadingWebsite(false)
    }
  };

  const handleChangeTeam = (value) => {
    setPagination({...pagination, teamId: value});
  }
  const handleChangeStatus = (value) => {
    setPagination({...pagination, status: value});
  }

  const handleChangeCreatedBy = (value) => {
    setPagination({...pagination, createdBy: value});
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render: (_, text, index) =>
        (pagination.page - 1) * pagination.limit + index + 1,
      width: 60,
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      render: (domain, _) => {
        const url = domain.startsWith('http') ? domain : `https://${domain}`;
        return (
            <a className='long-text' href={url} target='_blank'>
              {_.domainReplace}
            </a>
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'createName',
      render: (_, {createName}) => {
        return createName ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#e5e5e5', color: '#000'}}>{createName.charAt(0)}</Avatar>
            <span className="ml-1">{createName}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Team',
      dataIndex: 'team',
      render: (_, {team}) => {
        return team ?
          <div className="flex items-center">
            <Avatar style={{backgroundColor: '#fde3cf', color: '#f56a00'}}>{team.charAt(0)}</Avatar>
            <span className="text-[#f56a00] ml-1">{team}</span>
          </div>
          : '';
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
    },
    {
      title: 'Thời gian sửa',
      dataIndex: 'updated_at',
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      render: (_, {status}) => {
        return (
          <Tag color={status === 'active' || status === 'SEO' || status === 'DOMAIN MỚI' ? 'green' : 'red'} key={status}>
            {status?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Tổng tiền index',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      width: 130,
    },
    {
      title: 'Thao tác',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space size='middle' className='gap-2'>
          <Tooltip title='đề xuất'>
            <button
              onClick={() => showFolder(record.key)}
              className='bg-green-600 action-button'
            >
              <FolderOpenOutlined/>
            </button>
          </Tooltip>
          <Tooltip title='chi tiết'>
            <button
              onClick={() => showInfo(record.key)}
              className='bg-blue-600 action-button'
            >
              <EyeOutlined/>
            </button>
          </Tooltip>
          <button
            onClick={() => onEdit(record)}
            className='bg-[#fd9900] action-button'
          >
            <EditOutlined/>
          </button>
          {localStorage.getItem('role') !== 'user' && (
            <Popconfirm
              cancelButtonProps={{
                className: 'bg-red-500 text-white btn-del',
              }}
              okButtonProps={{
                className: 'bg-blue-600 text-white',
              }}
              title='Xóa domain'
              description='Bạn muốn xóa domain này?'
              onConfirm={() => onDelete(record.key)}
              okText='Yes'
              cancelText='No'
              okType=''
            >
              <button className='bg-red-600 action-button'>
                <DeleteOutlined/>
              </button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Flex gap={'small'} wrap='wrap' className='mb-10 flex gap-2'>
        <Space.Compact style={{width: 300}}>
          <Input
            addonBefore={<SearchOutlined/>}
            placeholder='Tìm kiếm domain'
            onChange={debounceSearch}
            allowClear
          />
        </Space.Compact>

        {roleAuth === 'admin' ?
          <Select
            className='w-[240px]'
            placeholder='Lọc theo team'
            showSearch
            onChange={handleChangeTeam}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            }
          >
            <Option value=''>Tất cả team</Option>
            {teams?.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
          : null
        }

        {roleAuth !== 'user' ?
          <Select
            className='w-[240px]'
            placeholder='Lọc theo user tạo'
            showSearch
            onChange={handleChangeCreatedBy}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            }
          >
            <Option value=''>Tất cả user</Option>
            {users?.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
          : null
        }

        <Select
            className='w-[240px]'
            placeholder='Lọc theo trạng thái'
            showSearch
            onChange={handleChangeStatus}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0
            }
        >
          <Option value=''>Tất cả trạng thái</Option>
          {DOMAIN_STATUS?.map((item) => (
              <Option key={item.toUpperCase()} value={item.toUpperCase()}>
                {item.toUpperCase()}
              </Option>
          ))}
        </Select>
      </Flex>

      <div className='mt-5 flex'>
        {localStorage.getItem('role') !== 'user' && (<>
          <Button
              type='primary'
              className='add-btn flex items-center'
              style={{marginRight: 8}}
              onClick={handleAdd}
          >
            Thêm Domain
          </Button>
        </>)}
        {localStorage.getItem('role') !== 'user' && (
          <>
            <Button
              type='primary'
              danger
              style={{marginRight: 8}}
              onClick={handleDeleteSelectRows}
              disabled={selectedRowKeys.length === 0}
              className='btn-del-all flex items-center '
            >
              Xóa tất cả
            </Button>
            <Button
              style={{marginRight: 8}}
              onClick={() => handleExport(websiteData, headerRowFile, 'domain')}
              className='btn-excel flex items-center'
              disabled={websiteData?.length === 0}
            >
              Xuất Excel
            </Button>
          </>
        )}
        <Button
          type='primary'
          onClick={() => getAllWebsite()}
          className='add-reset flex items-center bg-slate-600 text-white'
          disabled={loading}
        >
          Reload
        </Button>
      </div>

      <DataTable
        listData={dataSource}
        pageSize={pagination.limit}
        columns={columns}
        indexPage={pagination.page}
        totalPage={+totalPage}
        onPageChange={onPageChange}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        scroll={{x: 1500}}
      />

      <ModalView
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={dataView}
      />
      <ModalFunction
        isModalFunction={isModalFunction}
        setIsModalFunction={setIsModalFunction}
        dataEdit={editData}
        onFinish={onFinish}
        title={titleModal}
        form={form}
        isLoadingWebsite={isLoadingWebsite}
      />
      {/* {localStorage.getItem("role") !== "user" && (
        <ModalFunction
          isModalFunction={isModalFunction}
          setIsModalFunction={setIsModalFunction}
          dataEdit={editData}
          onFinish={onFinish}
          title={titleModal}
          form={form}
        />
      )} */}
    </div>
  );
}
