import {Button, Divider, Form, Input, message, Modal, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {getConfigIndex, getWebsite} from "../../../services/api";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export default function ModalCheckLinkNow(props) {
  const {
    title,
    show,
    handleCloseModal,
    form,
    submitModal,
    userId,
  } = props;
  const { Option } = Select;

  const [websites, setWebsites] = useState([]);
  const [configs, setConfigs] = useState([]);

  const fetchWebsite = async () => {
    try {
      let resData = await getWebsite({ limit: 9999 });
      setWebsites(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  const fetchConfig = async () => {
    try {
      let resData = await getConfigIndex({ limit: 9999 });
      setConfigs(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchConfig();
    fetchWebsite();
  }, []);

  return (
    <Modal
      title={title}
      open={show}
      onCancel={() => handleCloseModal()}
      footer={null}
    >
      <Divider />
      <Form
          layout='vertical'
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
          configIndexId: configs[0]?._id,
        }}
        onFinish={submitModal}
        autoComplete="off"
      >
        <Form.Item
            className='relative z-0 w-full mb-6 group'
            label='Domain'
            name='websiteId'
            rules={[
              {
                required: true,
                message: "Không được để trống!",
              },
            ]}
        >
          <Select
              placeholder='Chọn domain'
              allowClear
              showSearch
              filterOption={(input, option) =>
              {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
          >
            {websites?.map((item) => (
                <Option value={item._id || item.website._id} key={item._id || item.website._id}>
                  {item.domain || item.website.domain}
                </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.List
            name='keywords'
        >
          {(fields, {add, remove}, {errors}) => (
              <>
                {fields.map((field, index) => (
                    <Form.Item
                        label={index === 0 ? 'Từ khóa' : ''}
                        required={false}
                        key={field.key}
                    >
                      <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Vui lòng nhập từ khóa.',
                            },
                          ]}
                          noStyle
                      >
                        <Input
                            placeholder='Nhập từ khóa'
                            allowClear
                            style={{
                              width: '90%',
                            }}
                        />
                      </Form.Item>
                      {fields.length > 0 ? (
                          <MinusCircleOutlined
                              className='ml-3'
                              onClick={() => remove(field.name)}
                          />
                      ) : null}
                    </Form.Item>
                ))}
                <Form.Item>
                  <Button
                      type='dashed'
                      onClick={() => add()}
                      style={{
                        width: '60%',
                      }}
                      icon={<PlusOutlined/>}
                  >
                    Thêm từ khóa
                  </Button>

                  <Form.ErrorList errors={errors}/>
                </Form.Item>
              </>
          )}
        </Form.List>

        <Form.Item
            className='hidden relative z-0 w-full mb-6 group'
            label='Config Index'
            name='configIndexId'
            rules={[
              {
                required: true,
                message: "Không được để trống!",
              },
            ]}
        >
          <Select
              defaultValue={configs[0]?._id}
              placeholder='Chọn config index'
              allowClear
              showSearch
              filterOption={(input, option) =>
              {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
          >
            {configs?.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="text-right mt-2">
          <Space>
            <Button type="primary" danger onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button htmlType="submit" type="primary" className="btn-modal">
              Xác nhận
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
}
