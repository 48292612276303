import {Form, message} from 'antd';
import React, {useEffect, useState} from 'react';
import {getSuggestDetail, updateSuggest} from '../../../services/api';
import {useParams} from 'react-router';
import {InfoSuggest} from './InfoSuggest';
import ModalFunction from '../Suggest/ModalFunction';
import history from '../../../utils/history';
import ListLink from './ListLink';

export default function DetailSuggest({socket}) {
  const [detailSuggest, setDetailSuggest] = useState();
  const [show, setShow] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const {suggestId} = useParams();
  const [form] = Form.useForm();
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    socket.on('connect', () => {
      console.log('Connected');
      socket.emit('login-success', {token});
    });
    fetchDetailSuggest();

    // const timeoutId = setTimeout(checkExtension, 1000);
    //
    // return () => {
    //   clearTimeout(timeoutId);
    // };

  }, []);

  // const checkExtension = () => {
  //   if (document.getElementById('CheckLinkIndex') === null) {
  //     alert('The extension "OK Check Index" is not installed in your browser. Please follow the installation instructions.');
  //   }
  // };

  const onSubmitRequestApproveSuccess = () => {
    fetchDetailSuggest();
  };

  // api detail
  const fetchDetailSuggest = () => {
    getSuggestDetail(suggestId).then((res) => {
      if (!res?.status) {
        history.push('/error');
      }
      setDetailSuggest(res?.data);
    }).catch((e) => {
      history.push('/error');
    });
  };

  // modal edit detail suggest\
  const oppenModalEdit = () => {
    fetchDetailSuggest();
    setShow(true);
    setTitleModal('Sửa Đề Xuất');
  };

  const submitEdit = async (value) => {
    await updateSuggest({
      id: suggestId,
      name: value.name,
      linkSuggest: value.linkSuggest,
      telegramId: value.telegramId,
      guaranteed: value.guaranteed,
      timer: value.timer,
      configIndexId: value.configIndexId,
    });

    fetchDetailSuggest();
    setShow(false);
    message.success('Sửa đề xuất thành công!');
  };

  return (
    <>
      {/* info suggest */}
      <InfoSuggest
        oppenModalEdit={oppenModalEdit}
        detailSuggest={detailSuggest}
        title='Thông tin đề xuất'
      />

      {/* modal, ModalFunction === component file suggest */}
      <ModalFunction
        dataEdit={detailSuggest}
        isModalFunction={show}
        setIsModalFunction={setShow}
        title={titleModal}
        form={form}
        onFinish={submitEdit}
      />

      {/* manage link */}
      <ListLink
        detailSuggest={detailSuggest}
        socket={socket}
        onSubmitRequestApproveSuccess={onSubmitRequestApproveSuccess}
        fetchDetailSuggest={fetchDetailSuggest}
      />
    </>
  );
}
