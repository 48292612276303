import React, {useEffect, useState} from 'react';
import {Badge, Flex, Form, message, Popconfirm, Select, Skeleton, Space, Tooltip} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, EyeOutlined} from '@ant-design/icons';
import DataTable from '../../common/DataTable';
import moment from 'moment';
import {
  deleteApproveSuggest,
  getAccountInfo,
  getApproveSuggest,
  getUsers,
  approveSuggest, updateApproveSuggest, getTeams
} from "../../../services/api";
import {APPROVE_STATUS, APPROVE_STATUS_ENUM} from "../../common/Enum";
import ModalReject from "./ModalReject";
import {useNavigate} from "react-router-dom";

export default function ApproveSuggest() {
  document.title = 'Duyệt đề xuất | CHECKLINK SERVICE OKVIP';
  const {Option} = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 1,
  });
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState([]);
  const [showModelReject, setShowModelReject] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [users, setUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [role, setRole] = useState('');
  const [filter, setFilter] = useState({
    status: '',
    suggestId: '',
    teamId: '',
    userId: '',
  });
  const [teams, setTeams] = useState([]);

  const fetchTeam = async () => {
    try {
      let resData = await getTeams({ limit: 9999 });
      setTeams(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };

  const dataSource = approve?.map((item, index) => {
    return {
      key: item._id,
      reason: item.reason,
      status: item.status,
      created_at: item.created_at ? moment(item.created_at).format('DD/MM/YYYY HH:mm') : '--',
      updated_at: item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY HH:mm') : '--',
      approved_at: item.approved_at ? moment(item.approved_at).format('DD/MM/YYYY HH:mm') : '--',
      user: item.userId,
      suggest: item.suggestId,
      team: item.teamId,
    }
  });

  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'suggest',
      render: (text, row) => {
        return row?.suggest ? row?.suggest?.name : '';
      },
    },
    {
      title: 'Người đề xuất',
      dataIndex: 'user',
      render: (text, row) => {
        return row?.user ? row?.user?.name : '';
      },
    },
    {
      title: 'Team',
      dataIndex: 'team',
      render: (text, row) => {
        let team = '';
        if (row?.team) {
          team = row?.team?.name;
        } else if (row?.user) {
          team = row?.user?.name;
        }
        return team;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 130,
      render: (text) => {
        return (
          <Badge
            count={APPROVE_STATUS[text]}
            showZero
            color={text === 'waiting' ? 'orange' : text === 'approved' ? 'green' : 'red'}
          />
        );
      },
    },
    {
      title: 'Ngày tạo',
      width: 150,
      dataIndex: 'created_at',
    },
    {
      title: 'Ngày duyệt',
      dataIndex: 'approved_at',
      width: 150,
    },
    {
      title: 'Lý do từ chối',
      dataIndex: 'reason',
      width: 280,
      render: (text) => {
        return text ? text : '--';
      }
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 130,
      fixed: 'right',
      render: (text, data, index) => {
        const status = data.status
        return (
          <Space >
            <Tooltip title='Xem chi tiết'>
              <button
                onClick={() => redirectDetailSuggestPage(data.suggest._id)}
                className='bg-blue-600 action-button'
              >
                <EyeOutlined/>
              </button>
            </Tooltip>

            {(status === APPROVE_STATUS_ENUM.WAITING || status === APPROVE_STATUS_ENUM.REQUESTED) &&
              <>
                <Popconfirm
                  cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
                  okButtonProps={{
                    className: 'bg-blue-600 text-white',
                  }}
                  title='Duyệt đề xuất'
                  description='Bạn muốn duyệt đề xuất này?'
                  onConfirm={() => handleApproveSuggest(data)}
                  okText='Yes'
                  cancelText='No'
                  okType=''
                >
                  <button className='bg-[#16a34a] action-button'>
                    <CheckCircleOutlined/>
                  </button>
                </Popconfirm>

                <button
                  onClick={() => rejectSuggest(data)}
                  className='bg-gray-500 action-button'
                >
                  <CloseCircleOutlined/>
                </button>
              </>
            }
          </Space>
        )
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.limit]);

  useEffect(() => {
    getRole();
    getListUser();
    fetchTeam();
  }, [])

  useEffect(() => {
    fetchData();
  }, [filter]);

  const fetchData = () => {
    setLoading(true);
    const params = {
      page: pagination.page,
      limit: pagination.limit,
      ...(filter.status && {status: filter.status}),
      ...(filter.suggestId && {suggestId: filter.suggestId}),
      ...(filter.teamId && {teamId: filter.teamId}),
      ...(filter.userId && {userId: filter.userId}),
    }

    getApproveSuggest(params)
      .then((res) => {
        setApprove(res.data.items);
        setPagination((prev) => ({
          ...prev,
          total: res.data.pagination.total,
        }))
      })
      .catch(async (e) => {
        message.error('Lỗi hệ thống');
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const getRole = () => {
    getAccountInfo().then(res => {
      let role = res?.data?.roleId?.name
      if (res?.data?.email === 'superadmin@okvip.com') role = 'admin';
      setRole(role);
    })
  };
  

  const getListUser = () => {
    getUsers({role: 'user', limit: 99999}).then(res => {
      setUser(res?.data?.items);
      setUserOptions(res?.data?.items);
    })
  };

  const onPageChange = (page, limit) => {
    setPagination((prev) => ({
      ...prev,
      page: page,
      limit: limit,
    }));
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteApproveSuggest(id)
      if (res?.status) {
        message.success('Xóa thành công!');
        await fetchData();
      } else {
        message.error(res?.message ?? 'Lỗi hệ thống');
      }
    } catch (e) {
      message.error('Lỗi hệ thống');
    }
  };

  const handleApproveSuggest = async (data) => {
    if (!data) {
      message.error('Lỗi hệ thống');
      return;
    }
    try {
      const res = await updateApproveSuggest(data.suggest._id, {
        status: 'approved',
      })
      if (res?.status) {
        message.success('Duyệt đề xuất thành công!');
        await fetchData();
      } else {
        message.error(res?.message ?? 'Lỗi hệ thống');
      }
    } catch (e) {
      message.error('Lỗi hệ thống');
    }
  };

  const rejectSuggest = async (data) => {
    if (!data) return;
    setDataEdit(data);
    setShowModelReject(true);
  };

  const handleCloseModal = () => {
    setShowModelReject(false);
  };

  const submitModal = async (values) => {
    await fetchData();
  };

  const onChangeStatus = async (value) => {
    setFilter((prev) => ({
      ...prev,
      status: value,
    }));
  }

  const onChangeTeam = async (value) => {
    // const userConcat = [].concat(teams);
    // if (value) {
    //   const filterUser = userConcat.filter(item => item?._id === value);
    //   setUserOptions(filterUser)
    // } else {
    //   setUserOptions(userConcat)
    // }
    setFilter((prev) => ({
      ...prev,
      teamId: value,
    }));
  }

  const onChangeUser = async (value) => {
    setFilter((prev) => ({
      ...prev,
      userId: value,
    }));
  }

  const redirectDetailSuggestPage = (id) => {
    navigate(`/detail/${id}`);
  };

  return (
    <>
      <Flex gap={'small'} wrap='wrap' className='mt-6'>
        <Select
          defaultValue=''
          className='w-[240px] mb-6 add-user-btn'
          onChange={onChangeStatus}
        >
          <Option value=''>Tất cả trạng thái</Option>
          <Option value='waiting'>Chờ duyệt</Option>
          <Option value='approved'>Đã duyệt</Option>
          <Option value='rejected'>Đã từ chối</Option>
        </Select>

        {role === 'admin' ?
          <Select
            className='w-[240px] mb-6 add-user-btn'
            onChange={onChangeTeam}
            placeholder='Lọc theo team'
            defaultValue=''
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value=''>Tất cả team</Option>
            {teams?.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
          : null
        }

        <Select
          className='w-[240px] mb-6 add-user-btn'
          onChange={onChangeUser}
          placeholder='Lọc theo user'
          defaultValue=''
          showSearch
          filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value=''>Tất cả user</Option>
          {userOptions?.map((item) => (
            <Option key={item._id} value={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Flex>

      <Skeleton loading={loading}>
        <DataTable
          columns={columns}
          listData={dataSource}
          pageSize={pagination.limit}
          indexPage={pagination.page}
          totalPage={pagination.total}
          onPageChange={onPageChange}
          scroll={{x: 1500}}
        />
      </Skeleton>

      <ModalReject
        form={form}
        handleCloseModal={handleCloseModal}
        show={showModelReject}
        submitModal={submitModal}
        dataEdit={dataEdit}
      />
    </>
  );
}