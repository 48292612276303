export const CONFIG_INDEX = {
  SYNBYTE: 'sinbyte',
  ONEHPING: '1hping',
  INDEXINJECT : 'indexinject',
  ONEINDEXER : '1indexer',
  GOOGLE : 'google',
}

export const INDEX_LINK = {
  INDEX: 'index',
  WAITING: 'waiting',
  NOINDEX: 'noindex',
  PROCESSING: 'processing',
  FAIL: 'fail',
  PROCESSING_CHECK_INDEX: 'processing_check_index',
}

export const APPROVE_STATUS = {
  waiting : 'Chờ duyệt',
  approved : 'Đã duyệt',
  rejected : 'Đã từ chối',
  requested : 'Chờ duyệt',
}

export const APPROVE_STATUS_ENUM = {
  WAITING : 'waiting',
  APPROVED : 'approved',
  REJECTED : 'rejected',
  REQUESTED : 'requested',
}

export const DOMAIN_STATUS = [
    'ACTIVE',
    'INACTIVE',
    'SEO',
    'KHÔNG SỬ DỤNG',
    'DOMAIN MỚI',
    '301',
    'PBN',
    'VỆ TINH',
    'TẠM NGƯNG',
    'HẬU ĐÀI',
    'WEB ĐÀO TẠO',
    'CHO TRỢ LÝ',
    'BACKUP',
    'CDN',
]

export const TYPE_SUGGEST = {
  STANDARD: 'standard',
  INDEX: 'index',
  OTHER: 'other',
    CLN: 'cln',
}

export const TYPE_SUGGESTS = [
  { value: 'standard', label: 'Chuẩn' },
  { value: 'index', label: 'Index' },
  { value: 'other', label: 'Khác' },
  { value: 'cln', label: 'Check Link' },
]