import {Modal} from "antd";
import {useState} from "react";

export default function ModalInstallExtension({isModalInstallExtension, setIsModalInstallExtension}) {
  const handleOk = () => {
  };

  const handleCancel = () => {
    setIsModalInstallExtension(false);
  };

  return (
    <Modal
      footer={false}
      title="Cài Extension để sử dụng phầm mềm"
      open={isModalInstallExtension}
      onCancel={handleCancel}
    >
      <div>
        <p>1. Hướng dẫn tải <a href="https://drive.google.com/file/d/1Siz2ik0c0k0CzRXXGU67IQhMD15L5E20/view"
                               target="_blank" className="text-orange-400">
          extension
        </a></p>
        <p>2. Link tải <a
          href="https://docs.google.com/document/d/1rJJJ49OjjhARWfrPjgBNKKctEg07gqvgk_gl1dHxkPg/edit?tab=t.0"
          target="_blank"
          className="text-orange-400">
          extension
        </a></p>
      </div>
    </Modal>
  );
}