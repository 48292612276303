import {Badge, Button, Col, Form, message, Popconfirm, Row} from 'antd';
import React, {useEffect, useState} from "react";
import {getApproveSuggestById, updateApproveSuggest} from "../../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import {APPROVE_STATUS} from "../../common/Enum";
import moment from "moment/moment";
import ListLinkApproveSuggest from "./ListLink";
import {CheckCircleOutlined, CloseCircleOutlined, Loading3QuartersOutlined} from "@ant-design/icons";
import ModalReject from "./ModalReject";

export default function DetailApproveSuggest() {
  document.title = 'Chi tiết duyệt đề xuất | CHECKLINK SERVICE OKVIP';
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [data, setData] = useState({});
  const [showModelReject, setShowModelReject] = useState(false);
  const [dataEdit, setDataEdit] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getApproveSuggestById(id).then(async (res) => {
      if (!res?.status) {
        message.error(res?.message || 'Lỗi hệ thống');
        navigate(`/approve-suggest`);
      } else {
        setData(res?.data);
      }
    }).catch(async (err) => {
      message.error('Lỗi hệ thống');
    }).finally(() =>
      setLoading(false)
    );
  }

  const approveSuggest = async () => {
    if (!data) {
      message.error('Lỗi hệ thống');
      return;
    }
    try {
      const res = await updateApproveSuggest(id, {
        status: 'approved',
      })
      if (res?.status) {
        message.success('Duyệt đề xuất thành công!');
        await fetchData();
      } else {
        message.error(res?.message ?? 'Lỗi hệ thống');
      }
    } catch (e) {
      message.error('Lỗi hệ thống');
    }
  }

  const rejectSuggest = async () => {
    if (!data) return;
    const dataEdit = {
      ...data,
      key: data._id,
    }
    setDataEdit(dataEdit);
    setShowModelReject(true);
  };

  const handleCloseModal = () => {
    setShowModelReject(false);
  };

  const submitModal = async (values) => {
    await fetchData();
  };

  return (
    <>
      <header className='p-3 flex justify-between'>
        <h1 className='text-2xl font-semibold'>Chi tiết duyệt đề xuất</h1>
        <div>
          <Popconfirm
            cancelButtonProps={{className: 'bg-red-500 text-white btn-del'}}
            okButtonProps={{
              className: 'bg-blue-600 text-white',
            }}
            title='Duyệt đề xuất'
            description='Bạn muốn duyệt đề xuất này?'
            onConfirm={() => approveSuggest()}
            okText='Yes'
            cancelText='No'
            okType=''
          >
            <Button
              className='bg-[#16a34a] text-white border-[#16a34a]'
              disabled={isSubmit}
              icon={isSubmit ? <Loading3QuartersOutlined spin/> : <CheckCircleOutlined/>}
            >
              Duyệt đề xuất
            </Button>
          </Popconfirm>

          <Button
            onClick={() => rejectSuggest()}
            className='bg-gray-500 text-white border-[#16a34a] ml-1'
            disabled={isSubmit}
            icon={isSubmit ? <Loading3QuartersOutlined spin/> : <CloseCircleOutlined/>}
          >
            Từ chối đề xuất
          </Button>
        </div>
      </header>
      <main className='m-4 mt-0'>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Tên đề xuất</span>
              <p className='w-[60%]'>{data?.suggestId?.name || ''}</p>
            </section>
          </Col>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Trạng thái</span>
              <p className='w-[60%]'>
                <Badge
                  count={APPROVE_STATUS[data.status]}
                  showZero
                  color={data.status === 'waiting' ? 'orange' : data.status === 'approved' ? 'green' : 'red'}
                />
              </p>
            </section>
          </Col>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Người đề xuất</span>
              <p className='w-[60%]'>{data?.userId?.name || ''} - {data?.userId?.email}</p>
            </section>
          </Col>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Ngày tạo</span>
              <p className='w-[60%]'>{data.created_at ? moment(data.created_at).format('DD/MM/YYYY HH:mm') : '--'}</p>
            </section>
          </Col>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Team</span>
              <p className='w-[60%]'>{data?.teamId?.name || ''}</p>
            </section>
          </Col>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Ngày duyệt</span>
              <p className='w-[60%]'>{data.approved_at ? moment(data.approved_at).format('DD/MM/YYYY HH:mm') : '--'}</p>
            </section>
          </Col>
          <Col span={12}>
            <section className='modal-info '>
              <span className='w-[40%]'>Lý do từ chối</span>
              <p className='w-[60%]'>{data?.reason || ''}</p>
            </section>
          </Col>
        </Row>
      </main>

      <header className='p-3 flex justify-between '>
        <h1 className='text-2xl font-semibold'>Danh sách link</h1>
      </header>

      <ListLinkApproveSuggest/>

      <ModalReject
        form={form}
        handleCloseModal={handleCloseModal}
        show={showModelReject}
        submitModal={submitModal}
        dataEdit={dataEdit}
      />
    </>
  );
}