import {Button, Divider, Form, Input, message, Modal, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {getConfigIndex, getWebsite} from "../../../services/api";

export default function ModalCheckLinkFree(props) {
  const {
    title,
    show,
    handleCloseModal,
    form,
    submitModal,
    userId,
  } = props;
  const { Option } = Select;

  const [websites, setWebsites] = useState([]);
  const [configs, setConfigs] = useState([]);

  const fetchWebsite = async () => {
    try {
      let resData = await getWebsite({ limit: 9999 });
      setWebsites(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  const fetchConfig = async () => {
    try {
      let resData = await getConfigIndex({ limit: 9999 });
      setConfigs(resData?.data?.items);
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchConfig();
    fetchWebsite();
  }, []);

  return (
    <Modal
      title={title}
      open={show}
      onCancel={() => handleCloseModal()}
      footer={null}
    >
      <Divider />
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitModal}
        autoComplete="off"
      >
        {/*{userId === 'leader' ? (*/}
        {/*  ''*/}
        {/*) : (*/}
        {/*  <Form.Item*/}
        {/*    className='relative z-0 w-full mb-6 group'*/}
        {/*    label='Domain'*/}
        {/*    name='websiteId'*/}
        {/*  >*/}
        {/*    <Select*/}
        {/*      placeholder='Chọn team'*/}
        {/*      allowClear*/}
        {/*      showSearch*/}
        {/*      filterOption={(input, option) =>*/}
        {/*      {*/}
        {/*        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
        {/*      }*/}
        {/*      }*/}
        {/*    >*/}
        {/*      {websites?.map((item) => (*/}
        {/*        <Option value={item._id} key={item._id}>*/}
        {/*          {item.domain}*/}
        {/*        </Option>*/}
        {/*      ))}*/}
        {/*    </Select>*/}
        {/*  </Form.Item>*/}
        {/*)}*/}
        <Form.Item
            className='relative z-0 w-full mb-6 group'
            label='Domain'
            name='websiteId'
            rules={[
              {
                required: true,
                message: "Không được để trống!",
              },
            ]}
        >
          <Select
              placeholder='Chọn domain'
              allowClear
              showSearch
              filterOption={(input, option) =>
              {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
          >
            {websites?.map((item) => (
                <Option value={item._id || item.website._id} key={item._id || item.website._id}>
                  {item.domain || item.website.domain}
                </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
            className='relative z-0 w-full mb-6 group'
            label='Config Index'
            name='configIndexId'
            rules={[
              {
                required: true,
                message: "Không được để trống!",
              },
            ]}
        >
          <Select
              placeholder='Chọn config index'
              allowClear
              showSearch
              filterOption={(input, option) =>
              {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
          >
            {configs?.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="text-right mt-2">
          <Space>
            <Button type="primary" danger onClick={handleCloseModal}>
              Hủy
            </Button>
            <Button htmlType="submit" type="primary" className="btn-modal">
              Xác nhận
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
}
